<div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
  <div class="card card0 border-0">
      <div class="row d-flex">
          <div class="col-lg-6">
              <div class="card1 pb-5">
                  <!-- <div class="row"> <img src="https://i.imgur.com/CXQmsmF.png" class="logo"> </div> -->
                  <div class="logo"></div>
                  <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> <img src="https://i.imgur.com/uNGdWHi.png" class="image"> </div>
              </div>
          </div>
          <div class="col-lg-6" [formGroup]="loginForm" #f="ngForm">
              <div class="card2 card border-0 px-4 py-5">
                  <br><br>
                  <div class="row px-3"> <label class="mb-1">
                          <h6 class="mb-0 text-sm">Email Address</h6>
                      </label> <input class="mb-4" type="text" name="email" formControlName=email placeholder="Enter a valid email address"> </div>
                  <div class="row px-3"> <label class="mb-1">
                          <h6 class="mb-0 text-sm">Password</h6>
                      </label> <input type="password" name="password" formControlName=password placeholder="Enter password"> </div>
                  <br>
                  <div class="row mb-3 px-3"> <button type="submit" (click)="submit()" [disabled]="!loginForm.valid"  class="btn btn-blue text-center">Login</button> </div>
                  <div class="row mb-4 px-3"> <small class="font-weight-bold">Don't have an account? <a [routerLink]="['/register']" routerLinkActive="router-link-active"  class="text-danger ">Register</a></small> </div>
              </div>
          </div>
      </div>
      <!-- <div class="bg-blue py-4">
          <div class="row px-3"> <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2021. All rights reserved.</small>
              <div class="social-contact ml-4 ml-sm-auto"> <span class="fa fa-facebook mr-4 text-sm"></span> <span class="fa fa-google-plus mr-4 text-sm"></span> <span class="fa fa-linkedin mr-4 text-sm"></span> <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span> </div>
          </div>
      </div> -->
  </div>
</div>
<!-- <div style="margin-bottom:25vh"></div> -->
