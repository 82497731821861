<nav class="navbar fixed-top navbar-expand-lg navbar-dark">
    <div class="container navbar-inner">
        <a class="navbar-brand img-fluid" routerLink="/home"><img class="logo img-responsive" src="../../assets/img/logos/logo_1.png" alt="" routerLink="/home" style=" margin-right: 10px;"></a>
        <div class="contact"><span class="material-icons">
            call
            </span> 9135048439
          </div>
        <button class="navbar-toggler navbar-toggler-right" type="button" (click)="isCollapsed = !isCollapsed" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
        <div class="navbar-collapse collapse" [attr.aria-expanded]="!isCollapsed" [ngClass]="{collapse: !isCollapsed}">
         
            <ul class="nav navbar-nav ml-auto mt-2" (click)="isCollapsed = !isCollapsed">
                <li class="nav-item" ><a class="nav-link font-weight-bold" routerLinkActive="active" routerLink="/home">Home</a></li>
                <li class="nav-item"><a class="nav-link font-weight-bold" routerLinkActive="active" routerLink="/product">Product</a></li>
                <li class="nav-item" ><a class="nav-link font-weight-bold" routerLinkActive="active" routerLink="/product-folder">Folder</a></li>
                <li class="nav-item" ><a class="nav-link font-weight-bold" routerLinkActive="active" routerLink="/contact">Enquiry</a></li>
            </ul>
        </div>
    </div>
</nav>

<br><br>
<div class="main">
    <router-outlet></router-outlet>
    <!-- <app-loading></app-loading> -->
</div>
<!-- Footer -->
<footer>
    <div id="container">
        <div id="part1">
            <div id="companyinfo">
            <a id="sitelink" href="#">Awzing</a>
                <p id="title">Working together for a healthier world.</p>
            </div>
            <div id="explore">
                <p id="txt1">Explore</p>
                <a class="link" [routerLink]="['/home']" routerLinkActive="router-link-active">Home</a>
                <a class="link" [routerLink]="['/product']" routerLinkActive="router-link-active">Product</a>
                <a class="link" [routerLink]="['/contact']" routerLinkActive="router-link-active">Contact</a>
            </div>
            <div id="visit">
                <p id="txt2">Visit</p>
                <br>
                <p class="text">Awzing </p>
                <p class="text">Panchkula(HR) </p>
                <!-- <p class="text">Pune 411009 </p> -->
            </div>
            <!-- <div id="subscribe">
                <p id="txt4">Subscribe to US</p>
                <form class="mt-4"> <input id="email" type="email" placeholder="Email"> </form> <a class="waves-effect waves-light btn">Subscribe</a>
                <p id="txt5">Connect With US</p> <i class="fab fa-facebook-square social fa-2x"></i> <i class="fab fa-linkedin social fa-2x"></i> <i class="fab fa-twitter-square social fa-2x"></i>
            </div> -->
        </div>
        <div id="part2">
            <p id="txt6"><i class="material-icons tiny"> copyright</i>copyright 2021 - All right reserved</p>
        </div>
    </div>
</footer>
