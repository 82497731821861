<header style="margin-top: -10vh;">
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100 img-fluid" src="./assets/img/slides/7.jpg" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100 img-fluid" src="./assets/img/slides/4.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100 img-fluid" src="./assets/img/slides/11.JPG" alt="Third slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
  </div>
</header>

<div class="container">

  <div class="row">
    <div class="col-md-5">
      <img class="welimg img-fluid" src="./assets/img/slides/wel1.jpg">
    </div>
    <div class="about col-md-6">
      <h3 style="color:green">Welcome to Awzing Heathcare pvt. ltd.</h3>
        <p>Awzing Healthcare pvt. ltd. is a pharmaceutical company headquartered at Panchkula(HR). Our main activities are focused on to improve the quality of life by providing better health services by developing and marketing quality and proprietary pharmaceutical product.
        Every molecule here is researched to trace the soul of happiness. Our significant achievements during the last few years have helped us build an Organization and infrastructure that has led Awzing to spread its wings. </p>
        <p>At Awzing, New and innovative business models are in motion to make the most of emerging opportunities. We aim to market high quality and affordable pharmaceuticals to be made available everywhere, everyday.</p>
        <div class="readMore"> <a routerLink="/about" class="btn">Read More</a></div>
      </div>
  </div>
  <p class="ourProduct">Our Products</p>

  <!-- <div class="row">
    <div class="mb-4">
      <div >
        <img *ngFor="let img of productList;let i = index"
          src='../../../assets/img/product_list/File {{i+1}}.jpeg'
          class="col-sm-3 w-100 shadow-1-strong rounded mb-4"
          alt="Boat on Calm Water"
        />
      </div>
    </div>
</div> -->

<div class="wrapper">
  <owl-carousel-o [options]="customOptions">

    <ng-container *ngFor="let slide of productList;let i = index">
      <ng-template class="slide" carouselSlide [id]="i+1">
        <img style="width: 95%;" src="../../../assets/img/product_list/File {{i+1}}.jpeg">
      </ng-template>
    </ng-container>

  </owl-carousel-o>  
</div>




<div style="margin-bottom: 15vh;"></div>
