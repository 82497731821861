<div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
  <div class="card card0 border-0">
      <div class="row d-flex">
          <div class="col-lg-6">
              <div class="card1 pb-5">
                  <!-- <div class="row"> <img src="../../../assets/img/logos/E-Pharmacy-Logo-Design.jpeg" class="logo"> </div> -->
                  <div class="logo"></div>
                  <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> <img
                          src="https://i.imgur.com/uNGdWHi.png" class="image"> </div>
              </div>
          </div>
          <div class="col-lg-6" [formGroup]="registerForm" #f="ngForm">
              <div class="card2 card border-0 px-4 py-5">
                  <br><br>
                  <div class="row">
                      <div class="col-sm-6">
                          <label class="mb-1">
                          <h6 class="mb-0 text-sm">First Name</h6>
                          </label>
                          <input class="mb-4" type="text" name="firstName" formControlName="firstName" placeholder="Enter First Name">
                      </div>
                      <div class="col-sm-6">
                          <label class="mb-1">
                          <h6 class="mb-0 text-sm">Last Name</h6>
                          </label> <input class="mb-4" type="text" name="lastName" formControlName="lastName" placeholder="Enter Last Name">
                      </div>
                  </div>
                  <div class="row px-3"> <label class="mb-1">
                          <h6 class="mb-0 text-sm">Email Address</h6>
                      </label> <input class="mb-4" type="email" name="email" formControlName="email" placeholder="Enter a valid email address" email>
                  </div>
                  <div class="row px-3"> <label class="mb-1">
                          <h6 class="mb-0 text-sm">Confirm Password</h6>
                      </label> <input type="password" name="password" formControlName="password" placeholder="Enter 8 character password"> </div>
                  <div class="row px-3"> <label class="mb-1">
                          <h6 class="mb-0 text-sm">Password</h6>
                      </label> <input type="password" name="confirmPassword" formControlName="confirmPassword" placeholder="Enter Confirm password"> </div>
                  <br>
                  <div class="row mb-3 px-3"> <button [disabled]="!registerForm.valid" (click)="submit()" type="submit" class="btn btn-blue text-center">Register</button>
                  </div>
                  <div class="row mb-4 px-3"> <small class="font-weight-bold">Don't have an account? <a
                              [routerLink]="['/login']" routerLinkActive="router-link-active"
                              class="text-danger ">Login</a></small> </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div style="margin-bottom:25vh"></div>
