<div class="container contact-form">
  <div class="contact-image">
      <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact" />
  </div>
  <form method="post">
      <h3>Drop Us a Message</h3>
      <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                  <input type="text" [(ngModel)]="Name" name="txtName" class="form-control" placeholder="Your Name *"
                       required />
              </div>
              <div class="form-group">
                  <input type="text" [(ngModel)]="Email" name="txtEmail" class="form-control" placeholder="Your Email *"
                      value="" />
              </div>
              <div class="form-group">
                  <input type="text" [(ngModel)]="Phone" name="txtPhone" class="form-control" placeholder="Your Phone Number *"
                      value="" />
              </div>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                  <textarea name="txtMsg" [(ngModel)]="Message" class="form-control" placeholder="Your Message *"
                      style="width: 100%; height: 150px;"></textarea>
              </div>
              <div class="form-group">
                  <input type="submit" (click)="sendMessage()" name="btnSubmit" class="btnContact" value="Send Message" />
              </div>
          </div>
      </div>
  </form>
</div>
