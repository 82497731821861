<!-- Gallery -->
<!-- <div class="container-fluid">
  <div class="row">
    <div class="mb-4">
      <div >
        <img *ngFor="let img of productList;let i = index"
          src='../../../assets/img/product_list/File {{i+1}}.jpeg'
          class="col-sm-3 w-100 shadow-1-strong rounded mb-4"
          alt="Boat on Calm Water"
        />
      </div>
    </div>
  
  </div>
</div> -->

<div class="hide-table container">

  <div class="mat-elevation-z8" #TABLE *ngIf="products && products.length > 0">
    <table mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let row; let i = index"> {{ i + 1 }} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="product_name" id="my-content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> PRODUCT NAME </th>
        <td class="name-hover" matTooltip="Check bill/payment" mat-cell *matCellDef="let row"> 
          {{row.Product_Name}} </td>
      </ng-container>

      <!-- Area Column -->
      <ng-container matColumnDef="composition" id="my-content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> COMPOSITION </th>
        <td mat-cell *matCellDef="let row"> {{row.COMPOSITION}} </td>
      </ng-container>

      <ng-container matColumnDef="pack">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> PACK </th>
        <td mat-cell *matCellDef="let row"> {{row.PACK}} </td>
      </ng-container>

      <!-- Total Bill Amount Left Column -->
      <ng-container matColumnDef="mrp" id="my-content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> MRP </th>
        <td mat-cell *matCellDef="let row">
          {{row.MRP}}
        </td>
      </ng-container>

      <!-- Add Bill Column -->
      <ng-container matColumnDef="addBill">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td (click)="addBill(row)" mat-cell *matCellDef="let row">
          <button mat-raised-button>
            Add Bill
          </button>

        </td>
      </ng-container>

      <ng-container matColumnDef="viewBill">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td (click)="viewBill(row)" mat-cell *matCellDef="let row">
          <button mat-raised-button>
            View Bill
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

</div>
<br><br>
  
<div class="show-card">
  <div *ngIf="dataSource && dataSource.filteredData">
    <div *ngFor="let product of dataSource.filteredData; let i = index">
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{product.Product_Name}}</mat-card-title>
        </mat-card-header>
        <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
        <mat-card-content class="ml-3">
          <p>
            {{product.COMPOSITION}}
          </p>
        </mat-card-content>
        <mat-card-subtitle class="ml-3"><strong>PACK: </strong> {{product.PACK}} <span class="ml-4"></span> <strong>MRP: </strong>Rs.{{product.MRP}}</mat-card-subtitle>
        
      </mat-card>
    </div>
  </div>
</div>
